
import { defineComponent, reactive, ref, watch } from "vue";
import firebase from "firebase";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "Admin Login",
  setup() {
    const router = useRouter();
    const toast = useToast();
    const logInData = reactive({
      email: "",
      password: "",
    });
    const validEmail = ref(false);
    const validPassword = ref(false);
    const formReady = ref(false);

    watch([logInData], () => {
      const emailRegx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

      if (emailRegx.test(logInData.email)) {
        validEmail.value = true;
      } else {
        validEmail.value = false;
      }

      if (logInData.password.length > 7) {
        validPassword.value = true;
      } else {
        validPassword.value = false;
      }

      if (validEmail.value && validPassword.value) {
        formReady.value = true;
      } else {
        formReady.value = false;
      }
    });

    const logIn = async () => {
      await firebase
        .auth()
        .signInWithEmailAndPassword(logInData.email, logInData.password)
        .then(() => {
          router.push({ path: "/fhbooking/admin" });
        })
        .catch((error) => {
          if (error.code === "auth/wrong-password") {
            toast.error(
              "Feil e-post eller passord! Prøv igjen og ta kontakt om feilen vedvarer."
            );
          } else {
            toast.error(
              "Feil i innloggingen. Prøv igjen og ta kontakt om feilen vedvarer."
            );
          }
        });
    };

    return {
      logInData,
      validEmail,
      validPassword,
      formReady,
      logIn,
    };
  },
});
