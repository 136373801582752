<template>
  <div>
    <img
      src="@/assets/FH_logo.png"
      alt="Gå til finstadbru hundesport bookingside"
      width="200"
    />
    <form @submit.prevent="logIn">
      <div class="form-group">
        <label for="email">E-post</label>
        <input
          type="text"
          name="email"
          id="email"
          class="form-control"
          :class="validEmail ? 'input-right' : 'input-wrong'"
          v-model="logInData.email"
        />
        <p v-if="!validEmail" class="input-error">* Epost må fylles ut</p>
      </div>
      <div class="form-group">
        <label for="password">Passord</label>
        <input
          type="password"
          name="password"
          id="password"
          class="form-control"
          :class="validPassword ? 'input-right' : 'input-wrong'"
          v-model="logInData.password"
        />
        <p v-if="!validPassword" class="input-error">
          * Passord må være minst 8 tegn
        </p>
      </div>
      <div v-if="formReady" class="form-group login-button">
        <input type="submit" value="Logg inn" class="btn btn-success" />
      </div>
    </form>
    <router-link to="/fhbooking">Tilbake</router-link>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch } from "vue";
import firebase from "firebase";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "Admin Login",
  setup() {
    const router = useRouter();
    const toast = useToast();
    const logInData = reactive({
      email: "",
      password: "",
    });
    const validEmail = ref(false);
    const validPassword = ref(false);
    const formReady = ref(false);

    watch([logInData], () => {
      const emailRegx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

      if (emailRegx.test(logInData.email)) {
        validEmail.value = true;
      } else {
        validEmail.value = false;
      }

      if (logInData.password.length > 7) {
        validPassword.value = true;
      } else {
        validPassword.value = false;
      }

      if (validEmail.value && validPassword.value) {
        formReady.value = true;
      } else {
        formReady.value = false;
      }
    });

    const logIn = async () => {
      await firebase
        .auth()
        .signInWithEmailAndPassword(logInData.email, logInData.password)
        .then(() => {
          router.push({ path: "/fhbooking/admin" });
        })
        .catch((error) => {
          if (error.code === "auth/wrong-password") {
            toast.error(
              "Feil e-post eller passord! Prøv igjen og ta kontakt om feilen vedvarer."
            );
          } else {
            toast.error(
              "Feil i innloggingen. Prøv igjen og ta kontakt om feilen vedvarer."
            );
          }
        });
    };

    return {
      logInData,
      validEmail,
      validPassword,
      formReady,
      logIn,
    };
  },
});
</script>
<style scoped>
.input-wrong {
  border: 1px solid red;
}
.input-right {
  border: 1px solid green;
}
.input-error {
  font-size: 0.8rem;
  color: red;
  margin-bottom: 0;
}
form {
  width: 40%;
  margin: 0 auto;
  text-align: left;
}
.form-group {
  margin-bottom: 1rem;
}
label {
  font-weight: bold;
}
.login-button {
  text-align: center;
}
@media screen and (max-width: 768px) {
  form {
    width: 70%;
  }
}
</style>
